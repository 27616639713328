<template>
  <div class="profile">
    <CategoryTitle :category="category" />
    <v-form ref="passwordData" v-model="changePasswordValid" v-if="showForm">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="newPassword"
            label="Password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="newConfirmPassword"
            label="Conferma Password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="toggleShowPassword"
            :rules="passwordConfirm"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            @click="handleUpdatePasswordSubmit"
            large
            depressed
            :disabled="disabledUpdate"
          >
            {{ $t("profile.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-card v-else class="elevation-0">
      <v-spacer></v-spacer>
      <v-card-text class="response-messages">
        <ResponseMessage :response="response" />
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import GrosMarketCustomService from "@/service/grosmarketCustomService";
import category from "~/mixins/category";
import CategoryTitle from "@/components/category/CategoryTitle";
import ResponseMessage from "@/components/ResponseMessage";

import { requiredValue, minLength, minNum } from "~/validator/validationRules";

export default {
  name: "UpdatePassword",
  components: { CategoryTitle, ResponseMessage },
  mixins: [category],
  data() {
    return {
      showForm: true,
      changePasswordValid: false,
      oldPassword: null,
      newPassword: null,
      newConfirmPassword: null,
      showPassword: false,
      passwordRules: [
        requiredValue("Digitare la password"),
        minLength(8, "La password deve essere lunga almeno 8 caratteri"),
        minNum(1, "La password deve contenere almeno una cifra")
      ],
      passwordConfirm: [
        requiredValue("Confermare la password"),
        v => v === this.newPassword || "Le password non coincidono"
      ]
    };
  },
  computed: {
    disabledUpdate() {
      return !this.changePasswordValid;
    }
  },
  methods: {
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async handleUpdatePasswordSubmit(e) {
      e.preventDefault();
      this.$refs.passwordData.validate();
      if (!this.changePasswordValid) {
        return;
      }

      const result = await GrosMarketCustomService.updatePassword(
        this.oldPassword,
        this.newPassword
      );
      if (result) {
        this.response = result.response;
        this.showForm = false;
      }
    },
    fetchUserData() {
      GrosMarketCustomService.getUser().then(user => {
        // this.user = user;
        if (user) {
          this.oldPassword = user.Password;
          this.newPassword = user.Password;
          this.newConfirmPassword = user.Password;
        }
      });
    }
  },
  mounted() {
    this.fetchUserData();
  }
};
</script>

<style lang="scss">
.update-password {
  .v-input__slot {
    background-color: $white !important;
  }
}
</style>
